

import {BackendError, HotelSpreadsheet, ReturnedError, RoomSpreadsheet} from "../../../data/BackendClasses";
import {useFormState} from "../../../data/Form";
import React, {useContext, useState} from "react";
import {GlobalContext} from "../../../data/GlobalContext";
import {postJsonToBackend} from "../../../data/network";
import {Dialog} from "primereact/dialog";
import {formHasErrors} from "../../../lib/helpers";
import {Chips, ChipsAddEvent, ChipsChangeEvent, ChipsRemoveEvent} from "primereact/chips";

const initialState:HotelSpreadsheetInitialState = {id: 0, name: '', propertyId: '', category: '', taxPercent: undefined, tax: undefined, resortFeePercent: undefined, resortFee:undefined, airbnbCom: undefined, rocketstayShare: undefined, pms: '', accessKey: '', rooms: [], currency: ''};

type HotelSpreadsheetInitialState = {
    id: number;
    name: string;
    propertyId: string;
    category: string;
    taxPercent: number|undefined;
    tax: number|undefined;
    resortFeePercent: number|undefined;
    resortFee: number|undefined;
    airbnbCom: number|undefined;
    rocketstayShare: number|undefined;
    pms: string;
    accessKey: string;
    rooms: RoomSpreadsheet[];
    currency: string;

}

export default function AddHotelSpreadsheetDialog(
    {
        showAddDialog,
        onClose,
        triggerReload
    }:{
        onClose: () => void,
        showAddDialog: boolean
        triggerReload: () => void
    }) {

    const {inputValues, handleInputChange, dirty} = useFormState(initialState)
    const erori: { [x: string]: string[] } = {
        name: [],
        propertyId: [],
        category: [],
        taxPercent: [],
        tax: [],
        resortFeePercent: [],
        resortFee: [],
        airbnbCom: [],
        rocketstayShare: [],
        pms: [],
        accessKey: [],
        rooms: [],
        currency: []
    };

    const [rooms, setRooms] = useState<{id:number, name: string}[]>([]);
    const [roomChips, setRoomChips] = useState<string[]>(rooms.map(room => room.name));
    const [roomsDirty, setRoomsDirty] = useState<boolean>(false);

    const [checkedReminder, setCheckedReminder] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    function addHotel(e: any){
        e.preventDefault();

        postJsonToBackend("/hotels-spreadsheet/add", {...inputValues, rooms})
            .then(rsp => {
                showToastMessage("Success", "Hotel added", "success");
                onClose();
                triggerReload();
            })
            .catch((err: ReturnedError) => {
                console.log(err);
                // showToastMessage("Error", "Something went bad and the hotel was not added", "error");
                showToastMessage("Error", err.errors[0].message, "error");
            })
    }

    function hotelIsModified(){
        return inputValues.name != initialState.name ||
            inputValues.propertyId != initialState.propertyId ||
            inputValues.category != initialState.category ||
            inputValues.taxPercent != initialState.taxPercent ||
            inputValues.airbnbCom != initialState.airbnbCom ||
            inputValues.rocketstayShare != initialState.rocketstayShare ||
            inputValues.pms != initialState.pms ||
            inputValues.accessKey != initialState.accessKey ||
            inputValues.currency != initialState.currency
    }

    function chechForErrors() {
        if (!inputValues.name && dirty) {
            erori.name.push("Hotel name is mandatory");
        }

        if (!inputValues.propertyId && dirty) {
            erori.propertyId.push("PropertyId is mandatory");
        }

        if (!inputValues.category && dirty) {
            erori.category.push("Category is mandatory");
        }

        if (!inputValues.taxPercent && dirty) {
            erori.taxPercent.push("Tax Percent is mandatory");
        }

        if (!inputValues.tax && dirty) {
            erori.tax.push("Tax is mandatory");
        }

        if (!inputValues.resortFeePercent && dirty) {
            erori.resortFeePercent.push("Resort Fee Percent is mandatory");
        }

        if (!inputValues.resortFee && dirty) {
            erori.resortFee.push("Resort Fee is mandatory");
        }

        if (!inputValues.airbnbCom && dirty) {
            erori.airbnbCom.push("Airbnb Com is mandatory");
        }

        if (!inputValues.rocketstayShare && dirty) {
            erori.rocketstayShare.push("Rocketstay Share is mandatory");
        }

        // if (!inputValues.pms && dirty) {
        //     erori.pms.push("PMS Share is mandatory");
        // }

        if (!inputValues.accessKey && dirty) {
            erori.accessKey.push("Access Key is mandatory");
        }

        if (!inputValues.currency && dirty) {
            erori.currency.push("Currency is mandatory");
        }

        if (!inputValues.rooms) {
            erori.rooms.push("Rooms are mandatory");
        }
    }

    chechForErrors();

    const handleNumericInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if(inputValue == ""){
            handleInputChange(event);
        } else if (isNumeric(inputValue)) {
            const numericValue = parseFloat(inputValue);
            if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
                handleInputChange(event);
            }
        }
    };

    const isNumeric = (inputString: string): boolean => {
        return /^[\d.]+$/.test(inputString);
    };

    const handleNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if(inputValue == ""){
            handleInputChange(event);
        } else if (isNumber(inputValue)) {
            handleInputChange(event);
        }
    };

    const isNumber = (inputString: string): boolean => {
        return /^[\d]+$/.test(inputString);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if(/^[0-4]$/.test(inputValue) || inputValue == ""){
            handleInputChange(event);
        }
    }

    return <>
        <Dialog
            header={`Add new hotel`}
            visible={showAddDialog}
            style={{ width: '70vw'}}
            onHide={onClose}
            pt={{
                root: {className: "!shadow-2xl"},
                content: {className: "!bg-[#efefef]  backdrop-blur-3xl"},
                header: {className: "!bg-[#efefef]  backdrop-blur-3xl !text-blue-500"},
                headerIcons: {className: "!text-white "},
                closeButton: {className: "!text-white hover:!text-blue-500 focus:!outline-none active:!outline-none"}
            }}
        >
            <div>
                <form onSubmit={addHotel} className="flex flex-col w-full">
                    <div className="w-full flex flex-row gap-1">
                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.name.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="name">Name</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.name.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="name"
                                id="name"
                                type="text"
                                value={inputValues.name}
                                onChange={handleInputChange}
                            />
                            {erori.name.length > 0 && <div className="text-red-500 text-sm">{erori.name[erori.name.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.propertyId.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="propertyId">Property ID</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.propertyId.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="propertyId"
                                id="propertyId"
                                type="text"
                                value={inputValues.propertyId}
                                onChange={handleNumberInputChange}
                            />
                            {erori.propertyId.length > 0 && <div className="text-red-500 text-sm">{erori.propertyId[erori.propertyId.length-1]}</div>}
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-1">
                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.accessKey.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="accessKey">Access Key</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.accessKey.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="accessKey"
                                id="accessKey"
                                type="text"
                                value={inputValues.accessKey}
                                onChange={handleInputChange}
                            />
                            {erori.accessKey.length > 0 && <div className="text-red-500 text-sm">{erori.accessKey[erori.accessKey.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.category.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="category">Category</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.category.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="category"
                                id="category"
                                type="text"
                                value={inputValues.category}
                                onChange={handleCategoryChange}
                            />
                            {erori.category.length > 0 && <div className="text-red-500 text-sm">{erori.category[erori.category.length-1]}</div>}
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-1">
                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.airbnbCom.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="airbnbCom">Airbnb Com (%)</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.airbnbCom.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="airbnbCom"
                                id="airbnbCom"
                                value={inputValues.airbnbCom}
                                onChange={handleNumericInputChange}
                            />
                            {erori.airbnbCom.length > 0 && <div className="text-red-500 text-sm">{erori.airbnbCom[erori.airbnbCom.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.rocketstayShare.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="rocketstayShare">Rocketstay Share (%)</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.rocketstayShare.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="rocketstayShare"
                                id="rocketstayShare"
                                value={inputValues.rocketstayShare}
                                onChange={handleNumericInputChange}
                            />
                            {erori.rocketstayShare.length > 0 && <div className="text-red-500 text-sm">{erori.rocketstayShare[erori.rocketstayShare.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.taxPercent.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="taxPercent">Tax/VAT Percent (%)</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.taxPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="taxPercent"
                                id="taxPercent"
                                value={inputValues.taxPercent}
                                onChange={handleNumericInputChange}
                            />
                            {erori.taxPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPercent[erori.taxPercent.length-1]}</div>}
                        </div>
                    </div>

                    {/*todo work here*/}
                    <div className="w-full flex flex-row gap-1">
                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.tax.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="tax">Tax/VAT USD (%)</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.tax.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="tax"
                                id="tax"
                                value={inputValues.tax}
                                onChange={handleNumericInputChange}
                            />
                            {erori.tax.length > 0 && <div className="text-red-500 text-sm">{erori.tax[erori.tax.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.resortFeePercent.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="resortFeePercent">Resort Fee/night (%)</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.resortFeePercent.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="resortFeePercent"
                                id="resortFeePercent"
                                value={inputValues.resortFeePercent}
                                onChange={handleNumericInputChange}
                            />
                            {erori.resortFeePercent.length > 0 && <div className="text-red-500 text-sm">{erori.resortFeePercent[erori.resortFeePercent.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.resortFee.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="resortFee">Resort Fee/night ($)</label>
                            <input
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.resortFee.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="resortFee"
                                id="resortFee"
                                value={inputValues.resortFee}
                                onChange={handleNumericInputChange}
                            />
                            {erori.resortFee.length > 0 && <div className="text-red-500 text-sm">{erori.resortFee[erori.resortFee.length-1]}</div>}
                        </div>
                    </div>

                    <div className="w-full flex flex-row gap-1">
                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.pms.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="pms">PMS</label>
                            <select
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.pms.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="pms"
                                id="pms"
                                value={inputValues.pms}
                                onChange={handleInputChange}
                            >
                                <option key="" value="">Nothing selected</option>
                                <option key="ROOMMASTER" value="ROOMMASTER">ROOMMASTER</option>
                                <option key="Highgate Gross Rate" value="Highgate Gross Rate">Highgate Gross Rate</option>
                                <option key="Selina Panama Gross Rate" value="Selina Panama Gross Rate">Selina Panama Gross Rate</option>


                            </select>
                            {erori.pms.length > 0 && <div className="text-red-500 text-sm">{erori.pms[erori.pms.length-1]}</div>}
                        </div>


                        <div className="mb-2 w-full">
                            <label className={`block mb-2 font-medium ${erori.currency.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="currency">Currency</label>
                            <select
                                className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.currency.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                                name="currency"
                                id="currency"
                                value={inputValues.currency}
                                onChange={handleInputChange}
                            >
                                <option key="" value="">Select currency</option>
                                <option key="USD" value="USD">USD</option>
                                <option key="MXN" value="MXN">MXN</option>
                                <option key="THB" value="THB">THB</option>
                                <option key="BRL" value="BRL">BRL</option>
                                <option key="AUD" value="AUD">AUD</option>
                                <option key="FJD" value="FJD">FJD</option>
                                <option key="CRC" value="CRC">CRC</option>

                            </select>
                            {erori.currency.length > 0 && <div className="text-red-500 text-sm">{erori.currency[erori.currency.length-1]}</div>}
                        </div>

                    </div>

                    <div className="w-full flex flex-col">
                        <label className={`w-full block mb-2 font-medium ${erori.rooms.length > 0 ? "text-red-500" : "text-blue-500"}`} htmlFor="rooms">Rooms</label>
                        <Chips
                            className={`w-full text-gray-900 rounded-lg ${erori.rooms.length > 0 ? " border-solid border-red-500" : " border-none"}`}
                            name="rooms"
                            id="rooms"
                            value={roomChips}
                            // value={roomsTest}
                            onRemove={(e: ChipsRemoveEvent) => {
                                const updatedArray = roomChips.filter((str) => str != e.value);
                                const updatedRooms = rooms.filter((room) => room.name != e.value);

                                setRoomChips(updatedArray);
                                setRooms(updatedRooms);
                                setRoomsDirty(true);
                            }}
                            onAdd={(e: ChipsAddEvent) => {
                                if(isNumber(e.value)){
                                    setRoomChips([...roomChips, e.value])
                                    setRoomsDirty(true);
                                    setRooms([...rooms, {id: 0, name: e.value}])
                                }
                            }}
                            pt={{
                                container: {className: "!w-full !border-none"},
                                token: {className: "!mb-2"}
                            }}
                        />
                    </div>

                    {inputValues.propertyId && <div className="w-full flex flex-col content-center text-center mt-2">
                        <div className="flex flex-col">
                            <span>
                                <input
                                    type="checkbox"
                                    className="custom-form mr-5"
                                    checked={checkedReminder}
                                    onChange={() => setCheckedReminder((reminder) =>  !reminder)}
                                />
                                <i className="fa-solid fa-exclamation fa-shake text-orange-500 fa-2x mr-2"/>
                                Don't forget to add the 2 links in Properties-Access-Booking Webhook-URL
                            </span>
                            <span>{`http://72.44.49.186:5001/page?property=[PROPERTYID]`}</span>
                            <span>{`http://3.229.51.2:5001/page?property=[PROPERTYID]`}</span>
                        </div>
                    </div>}


                    <div className="w-full text-center">
                        {!formHasErrors(erori) && (dirty || roomsDirty || hotelIsModified()) && checkedReminder && <button
                            type="submit"
                            className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 px-2 py-1 mt-3"
                        >
                            Save
                        </button>}
                    </div>
                </form>
            </div>
        </Dialog>
    </>


}